import React, { useState } from 'react';
import './NumberLine.css'; // Import the CSS file for styling
import { roundToThreeSigDigs } from './App.js';





const NumberLine = ({ responseDetail }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const answer = responseDetail?.answer || '';
  const estimates = responseDetail?.estimates || [];
  const sorted_estimates=[...estimates];

  let min_value = 0;
  let max_value = 0;
  const min_px = 60;
  const max_px = 940;

  if (responseDetail && estimates.length > 0) {
    estimates.sort((a, b) => (Math.abs(a.output.estimate-answer) - Math.abs(b.output.estimate-answer)));
  }
  if (responseDetail && estimates.length > 0) {
    sorted_estimates.sort((a, b) => ((a.output.estimate) - (b.output.estimate)));
    min_value = sorted_estimates[Math.floor(estimates.length / 4)].output.estimate;
    max_value = sorted_estimates[Math.floor(estimates.length / 4) + Math.floor(estimates.length / 2) - 1].output.estimate;
  }

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(-1);
  };

  return (
    <>
      {responseDetail && estimates.length > 0 ? (
        <>
          <b>{roundToThreeSigDigs(estimates[0].output.estimate)}</b>
          <br/>
          <h2>
            ({roundToThreeSigDigs(sorted_estimates[Math.floor(estimates.length / 4)].output.estimate)} &nbsp; &mdash; &nbsp;
            {roundToThreeSigDigs(sorted_estimates[Math.floor(estimates.length / 4) + Math.floor(estimates.length / 2) - 1].output.estimate)})
          </h2>
          <div style={{ width: '70%' }}>
            <div className="svg-container">
              <svg width="100%" height="100" viewBox="0 0 1000 100" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: 0 }}>
                {/* Horizontal line */}
                <line x1="10" y1="50" x2="990" y2="50" stroke="black" strokeWidth="4" />
                {/* Left arrow */}
                <polygon points="5,50 20,44 20,56" fill="black" />
                {/* Right arrow */}
                <polygon points="995,50 980,44 980,56" fill="black" />
                {/* Left tick */}
                <line x1="60" y1="40" x2="60" y2="60" stroke="black" strokeWidth="2" />
                {/* Right tick */}
                <line x1="940" y1="40" x2="940" y2="60" stroke="black" strokeWidth="2" />
                {/* Middle tick */}
                <line x1="500" y1="40" x2="500" y2="60" stroke="black" strokeWidth="2" />
              </svg>
              <svg width="100%" height="100" viewBox="0 0 1000 100" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: 0 }}>
                {sorted_estimates.map((estimate, index) => (
                  index >= Math.floor(estimates.length / 4) && index < (Math.floor(estimates.length / 4) + Math.floor(estimates.length / 2)) ? (
                    <g
                      key={index}
                      className="tooltip-group"
                      onMouseEnter={() => handleMouseEnter(index)}
                      
                    >
                      <circle
                        cx={(estimate.output.estimate - min_value) / (max_value - min_value) * (max_px - min_px) + min_px}
                        cy="50"
                        r="10"
                        fill={activeIndex === index ? '#2980b9' : 'lightgray'}
                        stroke="orange"
                        strokeWidth="1"
                        className="hover-target"
                      />
                       {activeIndex === index && (
                        <text
                          x={(estimate.output.estimate - min_value) / (max_value - min_value) * (max_px - min_px) + min_px}
                          y="30"
                          className="tooltip-text"
                          textAnchor="middle"
                        >
                          {roundToThreeSigDigs(estimate.output.estimate)}
                        </text>
                      )}
                    </g>
                  ) : null
                ))}
              </svg>
            </div>
            {responseDetail && estimates.length > 0  ? (
              estimates.map((estimate, index) => (
              <div className='description'>
              <h2>{roundToThreeSigDigs(estimate.output.estimate)} </h2>
              <p>{estimate.output.explanation}</p>
              <br/>
              <hr/>
              </div>

              ))
            ) : null}
          </div>
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <h1> Estimate Anything</h1>
          <p>Ask any question with a numerical answer</p>
          <br />
        
            <img src={`${process.env.PUBLIC_URL}/logo_no_background.png`} alt="Example" style={{ width: '30px', height: 'auto' }} />
          <br />

          <br />
          <br />
          <h2>"How much revenue (in USD) will LLMs generate in 2030?"</h2>
          <br />
          <h2>"How many ping pong balls fit on a 747?"</h2>
          <br />
          <h2>"What is the market size of solenoids in the US (in USD)?"</h2>
          <br />
          <h2>"How many alien civilizations live within 1,000 lightyears of earth?"</h2>
        </div>
      )}
    </>
  );
};

export default NumberLine;
