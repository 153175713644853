import React, { useState, useEffect, useCallback } from 'react';
import styled,{ keyframes } from 'styled-components';
import NumberLine from './NumberLine';
import {roundToThreeSigDigs} from './App.js'
//<p>{responseDetail.answer? roundToThreeSigDigs(responseDetail.answer):<></> }</p>


function Main({question,responseDetail,loading}) {

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;


  return (
    <>
        <main>
          <br/>
          <p style={{marginLeft:'1rem', marginRight:' 1rem'}}>{question}</p>

          {loading ? 
            <div className='spinner-container'>
            <Spinner /> </div>
            : 
            <>
            <div style={{ width: '100%' }}>
            <NumberLine responseDetail={responseDetail} />
            </div>
            </>
          }
        </main>

    </>
  );
}

export default Main;
