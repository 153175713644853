import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import HeaderSidebar from './HeaderSidebar';
import Main from './Main';
import axios from 'axios';
import styled from 'styled-components';

export function roundToThreeSigDigs(num) {
    if (num === 0) return '0';

    const digits = Math.floor(Math.log10(Math.abs(num))) + 1;

    if (digits < 0) {
        const rounded = Number(num.toPrecision(3));
        if (rounded.toString().includes('e')) {
            return rounded.toExponential();
        } else {
            const decimalPlaces = Math.abs(digits) + 2;
            return rounded.toFixed(decimalPlaces);
        }
    } else {
        const factor = Math.pow(10, digits - 3);
        const rounded = Math.round(num / factor) * factor;
        return rounded.toLocaleString('en-US');
    }
}

const Footer = styled.footer`
  height: 150px;
  color: F3F3F3;
  position: fixed;
  text-align: center;
  align-items: center;
  bottom: 0;
  width: 100%;
`;

const TextArea = styled.textarea`
  flex-grow: 1;
  border: none;
  padding: 10px 20px;
  background-color: transparent;
  color: black;
  outline: none;
  resize: none;
  overflow: hidden;
  font-family: Arial, sans-serif;
  font-size: 0.9rem; /* Converted from 16px */
   

`;

const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  width: 80%;

  bottom: 3rem;
  padding: 0;
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  border: none;
  transition: background-color 0.3s;
  display: flex;
  margin-right:0.75rem;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #2980b9;
  }
`;

function App() {
  const [question, setQuestion] = useState('');
  const [questionDisplay, setQuestionDisplay] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseList, setResponseList] = useState([]);
  const textAreaRef = useRef(null);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [question]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const postData = {
      question: question,
      detail: "False",
      strength: 0
    };
    if(question === questionDisplay){
      postData.starterlist = answer.estimates;
    }

    setQuestionDisplay(question);

    try {
      setLoading(true);
      const response = await axios.post('https://babbij.xyz/api/estimate', postData, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 45000
      });
      setAnswer(response.data);
      const parsedMessage = { 'question': response.data.question, 'answer': roundToThreeSigDigs(response.data.answer) };
      setResponseList([response.data, ...responseList]);
    } catch (error) {
      console.error('Error submitting question: ', error);
      setAnswer('Error submitting question');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <>
      <HeaderSidebar responseList={responseList} setAnswer={setAnswer} setQuestionDisplay={setQuestionDisplay} />
      <Main question={questionDisplay} responseDetail={answer} loading={loading} />
      <Footer>
        <main>
          <form onSubmit={handleSubmit}>
            <InputButtonContainer>
              <TextArea
                ref={textAreaRef}
                value={question}
                onChange={handleQuestionChange}
                onKeyPress={handleKeyPress}
                placeholder="Estimate Anything"
                disabled={loading}
              />
              <Button type="submit" disabled={loading}>
                {loading ? '...' : 'Go'}
              </Button>
            </InputButtonContainer>
          </form>
        </main>
      </Footer>
    </>
  );
}

export default App;
